import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import NextLink from 'next/link'
import UIChip from '@audioeye-marketing/ui/src/components/Chip'
import ChevronRight from '@audioeye/ui-icons/Line/Arrows/ChevronRight'

const Chip = ({ blok }) => {
  const triggerBlok = blok?.trigger?.[0]
  const FinalChip = (
    <UIChip
      {...storyblokEditable(blok)}
      component="div"
      slotProps={{ action: { component: NextLink } }}
      size="sm"
      variant="soft"
      color="info"
      endDecorator={<ChevronRight />}
    >
      {blok?.text}
    </UIChip>
  )

  return triggerBlok ? <StoryblokServerComponent blok={triggerBlok} triggerComponent={FinalChip} /> : FinalChip
}

export default Chip
