import React from 'react'
import NextLink from 'next/link'

const Link = ({ blok, trigger }) => {
  let href = '/'
  const target = blok?.link?.target
  const cloneProps = {
    // * Necessary for CookieYes. All links will be considered a CookieYes Link if the url is #
    className: Boolean(blok?.link?.cached_url === '#') && 'cky-banner-element',
  }

  if (blok?.link?.linktype === 'story') {
    href = blok?.link?.cached_url === 'home' ? '/' : `/${blok?.link?.cached_url}`
  } else {
    href = blok?.link?.cached_url
  }

  if (trigger?.props?.slotProps) {
    cloneProps.slotProps = { ...trigger.props.slotProps }
    cloneProps.slotProps.action.href = href
    cloneProps.slotProps.action.target = target
  } else {
    cloneProps.href = href
    cloneProps.target = target
    cloneProps.component = NextLink
  }

  const clonedTrigger = React.cloneElement(trigger, {
    ...cloneProps,
  })

  return clonedTrigger
}

export default Link
