import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Section from '@audioeye-marketing/ui/src/components/Section'
import Container from '@audioeye-marketing/ui/src/components/Container'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import Box from '@audioeye-marketing/ui/src/components/Box'
import Grid from '@audioeye-marketing/ui/src/components/Grid'
import translateSectionStyles from '@/lib/translate-section-styles'

const LogoBanner = ({ blok, rels }) => {
  const sectionStyles = translateSectionStyles(blok?.section_styles?.[0])
  const titleAndCtaAlignment =
    blok?.variant === 'default'
      ? 'center'
      : blok?.variant === 'two-column'
        ? // left isn't a flex option so it will default to flex-start
          { xs: 'center', lg: 'left' }
        : { xs: 'center', md: 'left' }

  const title = blok?.title ? (
    <Typography
      level="title-lg"
      sx={{
        textAlign: titleAndCtaAlignment,
        flex: 3,
      }}
    >
      {blok?.title}
    </Typography>
  ) : null

  return (
    <Section {...storyblokEditable(blok)} {...sectionStyles} textAlign="center">
      <Container constraint="lg">
        <Stack spacing={5}>
          {blok?.variant !== 'two-column' && title}
          <Stack
            spacing={{ xs: blok?.variant === 'two-column' ? 3 : 0, md: 8 }}
            direction={{ xs: blok?.variant === 'two-column' ? 'column' : 'row', lg: 'row' }}
            justifyContent="space-between"
            alignItems="center"
          >
            {blok?.variant === 'two-column' && title}
            <Grid
              container
              columnSpacing={4}
              rowSpacing={2}
              sx={{
                flexWrap: 'wrap',
                display: 'flex',
                justifyContent: {
                  xs: 'center',
                  md:
                    blok?.variant !== 'left-aligned' && blok?.justify_logos === 'squished'
                      ? 'center'
                      : blok?.justify_logos === 'squished'
                        ? 'flex-start'
                        : 'space-between',
                  lg:
                    blok?.variant !== 'default' && blok?.justify_logos === 'squished'
                      ? 'flex-start'
                      : blok?.justify_logos === 'squished'
                        ? 'center'
                        : 'space-between',
                },
                alignItems: blok?.variant === 'left-aligned' ? { xs: 'flex-start', sm: 'center' } : 'center',
                width: '100%',
                '--Grid-columns': 5,
                flex: 9,
              }}
            >
              {blok?.logos?.map((currentLogo) => {
                return (
                  <Grid
                    key={currentLogo?._uid}
                    sx={{
                      minWidth: { xs: '120px', md: 'auto' },
                    }}
                    xs={1}
                  >
                    <Box sx={{ maxWidth: '144px', margin: 'auto' }}>
                      <StoryblokServerComponent blok={currentLogo} rels={rels} />
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </Stack>
          {blok?.cta?.map((currentCta) => (
            <Box
              key={blok?._uid}
              sx={{
                display: 'flex',
                justifyContent: titleAndCtaAlignment,
              }}
            >
              <StoryblokServerComponent
                blok={currentCta}
                rels={rels}
                backgroundColor={sectionStyles?.backgroundColor}
              />
            </Box>
          ))}
        </Stack>
      </Container>
    </Section>
  )
}

export default LogoBanner
