import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import UIListItem from '@audioeye-marketing/ui/src/components/ListItem'
import ListItemDecorator from '@audioeye-marketing/ui/src/components/ListItemDecorator'
import ListItemButton from '@audioeye-marketing/ui/src/components/ListItemButton'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import Share03 from '@audioeye/ui-icons/Line/General/Share03'
import determineButtonColor from '@/lib/determine-button-color'

const ListItem = ({ blok, backgroundColor }) => {
  const color = determineButtonColor({ backgroundColor })
  const triggerBlok = blok?.trigger?.[0]
  const trigger = triggerBlok?.trigger?.[0]
  const decorator = trigger?.link?.target === '_blank' ? <Share03 /> : null

  return (
    <UIListItem {...storyblokEditable(blok)} sx={{ padding: '0' }}>
      <StoryblokServerComponent
        blok={triggerBlok}
        triggerComponent={
          <ListItemButton color={color}>
            {Boolean(blok?.text) && <Typography level="body-sm">{blok?.text}</Typography>}
            {Boolean(decorator) && <ListItemDecorator>{decorator}</ListItemDecorator>}
          </ListItemButton>
        }
      />
    </UIListItem>
  )
}

export default ListItem
