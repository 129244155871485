import colors from '../theme/colors'

/**
 * @param {string} backgroundColor - string representing the background color
 * @param {boolean} inverse - boolean representing if certain things should be inverse
 * @return {object} - string representing the text color for the body and eyebrow
 */
const determineColors = ({ backgroundColor, inverse }) => {
  const settings = {
    [colors.neutral[50]]: {
      backgroundColor: inverse ? colors.common.white : colors.neutral[50],
      Typography: {
        eyebrow: colors.neutral[600],
        text: 'light',
      },
      ListItem: {
        listItemDecorator: colors.neutral[600],
      },
      Quote: {
        display: colors.secondary[500],
        Card: {
          display: colors.secondary[500],
        },
      },
      Svg: {
        image: '#1A1A1A',
        icon: colors.common.black,
      },
      Form: {
        Card: {
          backgroundColor: colors.common.white,
        },
      },
      Card: {
        border: 'rgba(var(--joy-palette-neutral-mainChannel, 99 107 116) / 0.2)',
        backgroundColor: inverse ? colors.neutral[50] : colors.common.white,
        Input: {
          invalid: colors.danger[500],
        },
        Svg: {
          icon: colors.common.black,
        },
      },
      JobOpeningsCard: {
        borderColor: colors.common.black,
      },
      Footer: {
        introText: colors.secondary[500],
        listTitle: colors.secondary[500],
        text: colors.neutral[900],
      },
      Accordion: {
        summaryText: 'inherit',
        icon: 'var(--Icon-color)',
        hoverBg: colors.common.white,
      },
    },
    [colors.common.white]: {
      backgroundColor: inverse ? colors.neutral[50] : colors.common.white,
      Typography: {
        eyebrow: colors.neutral[600],
        text: 'light',
      },
      ListItem: {
        listItemDecorator: colors.neutral[600],
      },
      Quote: {
        display: colors.secondary[500],
        Card: {
          display: colors.secondary[500],
        },
      },
      Svg: {
        image: '#1A1A1A',
        icon: colors.common.black,
      },
      CardWithForm: {
        backgroundColor: colors.neutral[50],
      },
      Card: {
        border: 'rgba(var(--joy-palette-neutral-mainChannel, 99 107 116) / 0.2)',
        backgroundColor: inverse ? colors.common.white : colors.neutral[50],
        Input: {
          invalid: colors.danger[500],
        },
        Svg: {
          icon: colors.common.black,
        },
      },
      JobOpeningsCard: {
        borderColor: colors.common.black,
      },
      Footer: {
        introText: colors.secondary[500],
        listTitle: colors.secondary[500],
        text: colors.neutral[900],
      },
      Accordion: {
        summaryText: 'inherit',
        icon: 'var(--Icon-color)',
        hoverBg: colors.neutral[50],
      },
    },
    [colors.common.black]: {
      backgroundColor: inverse ? colors.common.white : colors.common.black,
      Typography: {
        eyebrow: colors.secondary[300],
        text: 'dark',
      },
      ListItem: {
        listItemDecorator: colors.secondary[300],
      },
      Quote: {
        display: colors.common.white,
        Card: {
          display: colors.common.white,
        },
      },
      Svg: {
        image: colors.common.white,
        icon: colors.common.white,
      },
      CardWithForm: {
        backgroundColor: colors.common.white,
      },
      Card: {
        border: 'inherit',
        backgroundColor: inverse ? colors.common.black : colors.light[900],

        Input: {
          invalid: colors.danger[300],
        },
        Svg: {
          icon: colors.common.white,
        },
      },
      JobOpeningsCard: {
        borderColor: colors.common.white,
      },
      Footer: {
        introText: colors.common.white,
        listTitle: colors.common.white,
        text: colors.common.white,
      },
      Accordion: {
        summaryText: colors.common.white,
        icon: colors.common.white,
        hoverBg: colors.light[900],
      },
    },
    [colors.primary[900]]: {
      backgroundColor: inverse ? colors.common.white : colors.primary[900],
      Typography: {
        eyebrow: colors.primary[300],
        text: 'dark',
      },
      ListItem: {
        listItemDecorator: colors.primary[300],
      },
      Quote: {
        display: colors.primary[300],
        Card: {
          display: colors.primary[300],
        },
      },
      Svg: {
        image: colors.common.white,
        icon: colors.primary[300],
      },
      CardWithForm: {
        backgroundColor: colors.common.white,
      },
      Card: {
        border: 'inherit',
        backgroundColor: inverse ? colors.primary[900] : colors.light[900],

        Input: {
          invalid: colors.danger[400],
        },
        Svg: {
          icon: colors.primary[300],
        },
      },
      JobOpeningsCard: {
        borderColor: colors.primary[300],
      },
      Footer: {
        introText: colors.primary[200],
        listTitle: colors.primary[200],
        text: colors.common.white,
      },
      Accordion: {
        summaryText: colors.common.white,
        icon: colors.primary[300],
        hoverBg: colors.light[900],
      },
    },
    [colors.secondary[900]]: {
      backgroundColor: inverse ? colors.common.white : colors.secondary[900],
      Typography: {
        eyebrow: colors.secondary[300],
        text: 'dark',
      },
      ListItem: {
        listItemDecorator: colors.secondary[300],
      },
      Quote: {
        display: colors.secondary[300],
        Card: {
          display: colors.secondary[300],
        },
      },
      Svg: {
        image: colors.common.white,
        icon: colors.secondary[300],
      },
      CardWithForm: {
        backgroundColor: colors.common.white,
      },
      Card: {
        border: 'inherit',
        backgroundColor: inverse ? colors.secondary[900] : colors.light[900],

        Input: {
          invalid: colors.danger[400],
        },
        Svg: {
          icon: colors.secondary[300],
        },
      },
      JobOpeningsCard: {
        borderColor: colors.secondary[300],
      },
      Footer: {
        introText: colors.secondary[200],
        listTitle: colors.secondary[200],
        text: colors.common.white,
      },
      Accordion: {
        summaryText: colors.common.white,
        icon: colors.secondary[300],
        hoverBg: colors.light[900],
      },
    },
    [colors.tertiary[900]]: {
      backgroundColor: inverse ? colors.common.white : colors.tertiary[900],
      Typography: {
        eyebrow: colors.tertiary[300],
        text: 'dark',
      },
      ListItem: {
        listItemDecorator: colors.tertiary[300],
      },
      Quote: {
        display: colors.tertiary[300],
        Card: {
          display: colors.tertiary[300],
        },
      },
      Svg: {
        image: colors.common.white,
        icon: colors.tertiary[300],
      },
      CardWithForm: {
        backgroundColor: colors.common.white,
      },
      Card: {
        border: 'inherit',
        backgroundColor: inverse ? colors.tertiary[900] : colors.light[900],

        Input: {
          invalid: colors.danger[400],
        },
        Svg: {
          icon: colors.tertiary[300],
        },
      },
      JobOpeningsCard: {
        borderColor: colors.tertiary[300],
      },
      Footer: {
        introText: colors.tertiary[200],
        listTitle: colors.tertiary[200],
        text: colors.common.white,
      },
      Accordion: {
        summaryText: colors.common.white,
        icon: colors.tertiary[300],
        hoverBg: colors.light[900],
      },
    },
    [colors.info[900]]: {
      backgroundColor: inverse ? colors.common.white : colors.info[900],
      Typography: {
        eyebrow: colors.info[300],
        text: 'dark',
      },
      ListItem: {
        listItemDecorator: colors.info[300],
      },
      Quote: {
        display: colors.info[300],
        Card: {
          display: colors.info[300],
        },
      },
      Svg: {
        image: colors.common.white,
        icon: colors.info[300],
      },
      CardWithForm: {
        backgroundColor: colors.common.white,
      },
      Card: {
        border: 'inherit',
        backgroundColor: inverse ? colors.info[900] : colors.light[900],

        Input: {
          invalid: colors.danger[400],
        },
        Svg: {
          icon: colors.info[300],
        },
      },
      JobOpeningsCard: {
        borderColor: colors.info[300],
      },
      Footer: {
        introText: colors.info[200],
        listTitle: colors.info[200],
        text: colors.common.white,
      },
      Accordion: {
        summaryText: colors.common.white,
        icon: colors.info[300],
        hoverBg: colors.light[900],
      },
    },
  }

  return settings[backgroundColor] || settings[colors.neutral[50]]
}

export default determineColors
