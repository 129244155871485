import { storyblokEditable } from '@storyblok/react/rsc'
import Box from '@audioeye-marketing/ui/src/components/Box'

const FigmaEmbed = ({ blok }) => {
  return (
    <Box
      {...storyblokEditable(blok)}
      component="iframe"
      sx={{
        border: 'none',
      }}
      width="100%"
      height="1000px"
      title="Figma Prototype"
      src={blok?.figma_embed_url}
      allowFullScreen
    />
  )
}

export default FigmaEmbed
