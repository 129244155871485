import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import UICard from '@audioeye-marketing/ui/src/components/Card'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Box from '@audioeye-marketing/ui/src/components/Box'

const Card = ({ blok, backgroundColor }) => {
  const Wrapper = blok?.card ? UICard : Box
  const wrapperProps = blok?.card ? { noBorderRadius: true, variant: 'plain' } : {}
  const paddingSize = 4
  const mobilePaddingSize = 3
  const padding = blok?.collapse_padding ? 0 : paddingSize
  const mobilePadding = blok?.collapse_padding ? 0 : mobilePaddingSize
  const imageType = blok?.image?.[0]?.component
  const image = blok?.image?.map((currentImage) => (
    <StoryblokServerComponent key={currentImage?._uid} blok={currentImage} />
  ))

  return (
    <Wrapper
      {...storyblokEditable(blok)}
      {...wrapperProps}
      sx={{
        // * Done this way instead of size="lg" for Card so Box matches Card padding
        padding: { xs: mobilePadding, md: padding },
        width: '100%',
        gap: '0',
      }}
    >
      {imageType === 'Image' && (
        <Box
          sx={{
            marginRight: { xs: `-${mobilePadding * 8}px`, md: `-${padding * 8}px` },
            marginLeft: { xs: `-${mobilePadding * 8}px`, md: `-${padding * 8}px` },
            marginTop: { xs: `-${mobilePadding * 8}px`, md: `-${padding * 8}px` },
            paddingBottom: { xs: mobilePadding, md: padding },
          }}
        >
          {image}
        </Box>
      )}
      <Stack spacing={2} justifyContent="space-between" sx={{ height: '100%' }}>
        <Stack spacing={2}>
          {imageType === 'Icon' && image}
          {Boolean(blok?.heading) && (
            <Typography
              level={
                blok?.heading_level === 'display-lg' || blok?.heading_level === 'display-md'
                  ? 'body-lg'
                  : blok?.heading_level || 'title-lg'
              }
              displayLarge={blok?.heading_level === 'display-lg'}
              displayMedium={blok?.heading_level === 'display-md'}
            >
              {blok?.heading}
            </Typography>
          )}
          {blok?.text?.map((currentRichText) => (
            <StoryblokServerComponent
              key={currentRichText._uid}
              blok={currentRichText}
              backgroundColor={backgroundColor}
            />
          ))}
        </Stack>
        {blok?.cta?.map((currentCta) => (
          <StoryblokServerComponent key={currentCta?._uid} blok={currentCta} backgroundColor={backgroundColor} />
        ))}
      </Stack>
    </Wrapper>
  )
}

export default Card
