import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Grid from '@audioeye-marketing/ui/src/components/Grid'
import Typography from '@audioeye-marketing/ui/src/components/Typography'

const MegaMenuGroup = ({ blok }) => {
  return (
    <Stack
      {...storyblokEditable(blok)}
      spacing={2.5}
      sx={{ display: 'flex', justifyContent: 'flex-end', height: '100%' }}
    >
      <Grid xs={12}>
        <Typography level="body-sm">{blok?.title}&nbsp;</Typography>
      </Grid>
      <Stack justifyContent="space-between">
        <Stack spacing={1.5}>
          {blok?.items.map((currentItem) => {
            return <StoryblokServerComponent blok={currentItem} key={currentItem?._uid} />
          })}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default MegaMenuGroup
