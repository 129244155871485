import Image from 'next/image'
import NextLink from 'next/link'
import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Box from '@audioeye-marketing/ui/src/components/Box'
import Button from '@audioeye-marketing/ui/src/components/Button'
import Drawer from '@audioeye-marketing/ui/src/components/Drawer'
import Accordion from '@audioeye-marketing/ui/src/components/Accordion'
import AccordionGroup from '@audioeye-marketing/ui/src/components/AccordionGroup'
import AccordionSummary from '@audioeye-marketing/ui/src/components/AccordionSummary'
import AccordionDetails from '@audioeye-marketing/ui/src/components/AccordionDetails'
import List from '@audioeye-marketing/ui/src/components/List'
import ListItem from '@audioeye-marketing/ui/src/components/ListItem'
import ListItemButton from '@audioeye-marketing/ui/src/components/ListItemButton'
import FormControl from '@audioeye-marketing/ui/src/components/FormControl'
import FormLabel from '@audioeye-marketing/ui/src/components/FormLabel'
import Switch from '@mui/joy/Switch'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import Menu01 from '@audioeye/ui-icons/Line/General/Menu01'
import AudioEyeLogo from '@/assets/logos/audioeye-logo-black-purple-symbol.svg'

const Header = ({ blok, rels }) => {
  const logoLink = blok?.logo_link[0]
  const FinalLogo = (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Image priority src={AudioEyeLogo} width={151} height={36} alt="audio eye logo" />
    </Box>
  )

  return (
    <Box {...storyblokEditable(blok)}>
      {/* Utility Bar */}
      {blok?.utility_bar.map((utilityBar) => {
        return <StoryblokServerComponent key={utilityBar?._uid} blok={utilityBar} rels={rels} />
      })}
      {/* Header */}
      <Box
        component="header"
        sx={{
          padding: { xs: '1.25rem 1.5rem', lg: '1.25rem 4rem' },
          borderTop: '1px solid var(--joy-palette-divider)',
          borderBottom: '1px solid var(--joy-palette-divider)',
        }}
      >
        <Stack direction="row" spacing={8} justifyContent="space-between">
          <Stack direction="row" alignItems="center" justifyContent="center" spacing={{ xs: 4, lg: 6 }}>
            {/* Header Logo */}
            {logoLink ? <StoryblokServerComponent blok={logoLink} trigger={FinalLogo} /> : FinalLogo}

            {/* Desktop Header Navigation */}
            <Stack direction="row" spacing={3} sx={{ display: { xs: 'none', md: 'block' } }}>
              {/* {blok?.navigation_items?.map((navigationItem, index) => {
                return (
                  <StoryblokServerComponent
                    key={navigationItem?._uid}
                    blok={navigationItem}
                    color="neutral"
                    navItemNumber={index + 1}
                    rels={rels}
                    size="lg"
                  />
                )
              })} */}
            </Stack>
          </Stack>

          {/* Mobile Header Navigation */}
          <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
            <Drawer
              trigger={
                <Button color="neutral" size="md" variant="plain" endDecorator={<Menu01 />}>
                  Menu
                </Button>
              }
              anchor="right"
              sx={{ maxWidth: '400px !important', width: '100% !important' }}
            >
              <Box sx={{ padding: '50px 8px 0' }}>
                {FinalLogo}
                <Box component="nav" role="navigation">
                  <AccordionGroup>
                    <Accordion>
                      <AccordionSummary>Products</AccordionSummary>
                      <AccordionDetails>
                        <FormControl orientation="horizontal" sx={{ gap: 1 }}>
                          <FormLabel>Airplane Mode</FormLabel>
                          <Switch size="sm" />
                        </FormControl>
                        <List>
                          <ListItem>
                            <ListItemButton
                              component={NextLink}
                              href="/bloks"
                              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                            >
                              <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                                <Menu01 />
                                <Typography>asdf</Typography>
                              </Stack>
                              <Typography level="body-xs">AudioEye finds, fixes, and monitors issues</Typography>
                            </ListItemButton>
                          </ListItem>
                          <ListItem>
                            <ListItemButton
                              component={NextLink}
                              href="/bloks"
                              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                            >
                              <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                                <Menu01 />
                                <Typography>asdf</Typography>
                              </Stack>
                              <Typography level="body-xs">AudioEye finds, fixes, and monitors issues</Typography>
                            </ListItemButton>
                          </ListItem>
                          <ListItem>
                            <ListItemButton
                              component={NextLink}
                              href="/bloks"
                              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                            >
                              <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                                <Menu01 />
                                <Typography>asdf</Typography>
                              </Stack>
                              <Typography level="body-xs">AudioEye finds, fixes, and monitors issues</Typography>
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary>Solutions</AccordionSummary>
                      <AccordionDetails>Content</AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary>Hello</AccordionSummary>
                      <AccordionDetails>Content</AccordionDetails>
                    </Accordion>
                  </AccordionGroup>
                  <List>
                    <ListItem>
                      <ListItemButton
                        component={NextLink}
                        href="/bloks"
                        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                      >
                        <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                          <Menu01 />
                          <Typography>asdf</Typography>
                        </Stack>
                        <Typography level="body-xs">AudioEye finds, fixes, and monitors issues</Typography>
                      </ListItemButton>
                    </ListItem>
                    <ListItem>
                      <ListItemButton
                        component={NextLink}
                        href="/bloks"
                        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                      >
                        <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                          <Menu01 />
                          <Typography>asdf</Typography>
                        </Stack>
                        <Typography level="body-xs">AudioEye finds, fixes, and monitors issues</Typography>
                      </ListItemButton>
                    </ListItem>
                    <ListItem>
                      <ListItemButton
                        component={NextLink}
                        href="/bloks"
                        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                      >
                        <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                          <Menu01 />
                          <Typography>asdf</Typography>
                        </Stack>
                        <Typography level="body-xs">AudioEye finds, fixes, and monitors issues</Typography>
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Box>
              </Box>
            </Drawer>
          </Box>

          {/* Header CTAs */}
          <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
            {blok?.cta?.map((cta) => {
              return <StoryblokServerComponent key={cta?._uid} blok={cta} rels={rels} size="md" />
            })}
          </Box>
        </Stack>
      </Box>
    </Box>
  )
}

export default Header
