import { StoryblokServerComponent } from '@storyblok/react/rsc'
import UIMenu from '@audioeye-marketing/ui/src/components/Menu'
import Dropdown from '@audioeye-marketing/ui/src/components/Dropdown'

const Menu = ({ blok, trigger }) => {
  return (
    <Dropdown>
      {trigger}
      <UIMenu variant="plain" size="md" sx={{ paddingTop: 1, paddingBottom: 1, borderRadius: 8 }}>
        {blok?.items?.map((item) => {
          return <StoryblokServerComponent key={item?._uid} blok={item} />
        })}
      </UIMenu>
    </Dropdown>
  )
}

export default Menu
