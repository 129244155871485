import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Grid from '@audioeye-marketing/ui/src/components/Grid'
import Stack from '@audioeye-marketing/ui/src/components/Stack'

const MegaMenuColumn = ({ blok }) => {
  return (
    <Grid
      {...storyblokEditable(blok)}
      xs={6}
      lg={4}
      sx={{ pl: 3, pr: 8, pt: 3, pb: 3, borderRight: blok?.divider ? '1px solid var(--joy-palette-divider)' : '' }}
    >
      <Stack spacing={4}>
        {blok?.groups.map((currentGroup) => {
          return <StoryblokServerComponent blok={currentGroup} key={currentGroup?._uid} />
        })}
      </Stack>
    </Grid>
  )
}

export default MegaMenuColumn
