import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Accordion from '@audioeye-marketing/ui/src/components/Accordion'
import AccordionSummary from '@audioeye-marketing/ui/src/components/AccordionSummary'
import AccordionDetails from '@audioeye-marketing/ui/src/components/AccordionDetails'
import Box from '@audioeye-marketing/ui/src/components/Box'
import Stack from '@audioeye-marketing/ui/src/components/Stack'

const AccordionTabsItem = ({ blok, expanded, setCurrentTab, index, backgroundColor }) => {
  return (
    <Accordion
      {...storyblokEditable(blok)}
      expanded={expanded}
      onChange={(event, expanded) => {
        if (expanded) {
          setCurrentTab(index)
        }
      }}
    >
      <AccordionSummary>{blok?.summary}</AccordionSummary>
      <AccordionDetails>
        <Stack sx={{ mt: { xs: 2.75, md: 0 } }}>
          {blok?.image?.map((currentImage) => (
            <Box
              key={currentImage._uid}
              sx={{
                display: { xs: 'block', md: 'none' },
                width: { xs: '100%', sm: '66%' },
                margin: { xs: '0 auto 32px !important', md: 0 },
              }}
            >
              <StoryblokServerComponent blok={currentImage} />
            </Box>
          ))}
          {blok?.content?.map((currentRichText) => (
            <StoryblokServerComponent
              key={currentRichText?._uid}
              blok={currentRichText}
              options={{ NODE_PARAGRAPH: { level: 'body-md' } }}
              backgroundColor={backgroundColor}
            />
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

export default AccordionTabsItem
