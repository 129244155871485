import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'

const GlobalLogoBanner = ({ blok, rels }) => {
  const content = {
    ...blok?.logo_banner?.content,
    section_styles:
      blok?.section_styles?.length > 0 ? blok?.section_styles : blok?.logo_banner?.content?.section_styles,
    variant: blok?.variant?.length > 0 ? blok?.variant : blok?.logo_banner?.content?.variant,
    justify_logos: blok?.justify_logos?.length > 0 ? blok?.justify_logos : blok?.logo_banner?.content?.justify_logos,
  }

  return <StoryblokServerComponent {...storyblokEditable(blok)} blok={content} rels={rels} />
}

export default GlobalLogoBanner
