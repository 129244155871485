import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Box from '@audioeye-marketing/ui/src/components/Box'
import colors from '@audioeye-marketing/ui/src/theme/colors'

const UtilityBar = ({ blok }) => {
  const hasAnnouncement = blok?.announcement?.length > 0

  return (
    <Stack
      {...storyblokEditable(blok)}
      sx={{
        backgroundColor: colors.neutral[100],
        padding: '0.25rem 4rem',
        display: { xs: hasAnnouncement ? 'flex' : 'none', lg: 'flex' },
      }}
      direction="row"
      justifyContent={{
        xs: 'center',
        md: 'space-between',
        lg: blok?.announcement?.length > 0 ? 'space-between' : 'flex-end',
      }}
      alignItems="center"
    >
      {blok?.announcement?.map((item) => {
        return <StoryblokServerComponent key={item?._uid} blok={item} />
      })}

      <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
        {blok?.items?.map((item) => {
          return <StoryblokServerComponent key={item?._uid} blok={item} size="sm" color="neutral" />
        })}
      </Box>
    </Stack>
  )
}

export default UtilityBar
