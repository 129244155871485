import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'

const GlobalSvg = ({ blok, rels }) => {
  const content = {
    ...blok?.svg?.content,
  }

  return <StoryblokServerComponent {...storyblokEditable(blok)} blok={content} rels={rels} />
}

export default GlobalSvg
