import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import getResolvedStoryblokStory from '@/lib/get-resolved-storyblok-story'

const GlobalButton = ({ blok, rels, backgroundColor, fullWidth, size, color, navItemNumber }) => {
  const content = {
    ...getResolvedStoryblokStory({ item: blok?.button, rels }),
    variant: blok?.variant?.length > 0 ? blok?.variant : blok?.button?.content?.variant,
  }

  return (
    <StoryblokServerComponent
      {...storyblokEditable(blok)}
      blok={content}
      backgroundColor={backgroundColor}
      fullWidth={fullWidth}
      size={size}
      color={color}
      navItemNumber={navItemNumber}
    />
  )
}

export default GlobalButton
