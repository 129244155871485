import { storyblokEditable } from '@storyblok/react/rsc'
import Box from '@audioeye-marketing/ui/src/components/Box'

const AccessibilityStatement = ({ blok }) => (
  <Box
    {...storyblokEditable(blok)}
    component="iframe"
    sx={{
      width: '100%',
      height: '2300px',
      border: 'none',
      '@media (max-width: 1150px)': {
        height: '2250px',
      },
      '@media (max-width: 1050px)': {
        height: '2300px',
      },
      '@media (max-width: 900px)': {
        height: '2500px',
      },
      '@media (max-width: 768px)': {
        height: '2600px',
      },
      '@media (max-width: 700px)': {
        height: '2700px',
      },
      '@media (max-width: 425px)': {
        height: '3650px',
      },
      '@media (max-width: 375px)': {
        height: '4150px',
      },
      '@media (max-width: 320px)': {
        height: '5250px',
      },
    }}
    title="Accessibility Statement"
    src="https://portal.audioeye.com/accessibility-statement.html?domain=audioeye.com"
  />
)

export default AccessibilityStatement
