import { StoryblokServerComponent } from '@storyblok/react/rsc'

const Trigger = ({ blok, rels, triggerComponent, navItemNumber }) => {
  return blok?.trigger?.[0] ? (
    <StoryblokServerComponent
      blok={blok?.trigger?.[0]}
      rels={rels}
      trigger={triggerComponent}
      navItemNumber={navItemNumber}
    />
  ) : (
    triggerComponent
  )
}

export default Trigger
