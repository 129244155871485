import { storyblokEditable } from '@storyblok/react/rsc'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import Stack from '@audioeye-marketing/ui/src/components/Stack'

const Quote = ({ blok }) => {
  return (
    <Stack {...storyblokEditable(blok)} spacing={2}>
      <Typography level={blok?.quote_level || 'display-md'}>{`“${blok?.quote}”`}</Typography>
      <Typography level="body-lg">{`— ${blok?.name}`}</Typography>
    </Stack>
  )
}

export default Quote
