import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Grid from '@audioeye-marketing/ui/src/components/Grid'

const MegaMenuRow = ({ blok, underline }) => {
  return (
    <Grid
      {...storyblokEditable(blok)}
      container
      sx={{ borderBottom: underline ? '1px solid var(--joy-palette-divider)' : '' }}
    >
      {blok?.columns.map((currentColumn) => {
        return <StoryblokServerComponent blok={currentColumn} key={currentColumn?._uid} underline={underline} />
      })}
    </Grid>
  )
}

export default MegaMenuRow
