import Box from '../Box'
import determinePadding from '../../lib/determine-padding'
import determineColors from '../../lib/determine-colors'
import colors from '../../theme/colors'

const Section = ({
  children,
  padding,
  collapseTopPadding,
  collapseBottomPadding,
  collapseLeftPadding,
  collapseRightPadding,
  collapseRightPaddingMediumUp,
  backgroundColor,
  inverse,
  ...rest
}) => {
  const calculatedPadding = determinePadding({
    padding,
    collapseTopPadding,
    collapseBottomPadding,
    collapseLeftPadding,
    collapseRightPadding,
    collapseRightPaddingMediumUp,
  })

  const calculatedColors = determineColors({
    backgroundColor,
    inverse,
  })

  return (
    <Box
      component="section"
      sx={{
        ...rest.sx,
        position: 'relative', // Necessary for things like the textured image in Split Content
        backgroundColor: calculatedColors?.backgroundColor || colors.common.white,
        paddingTop: calculatedPadding.paddingTop,
        paddingBottom: calculatedPadding.paddingBottom,
        paddingLeft: calculatedPadding.paddingLeft,
        paddingRight: calculatedPadding.paddingRight,
        // * Typography - primary
        '.MuiTypography-h1, .MuiTypography-h2, .MuiTypography-h3, .MuiTypography-h4, .MuiTypography-title-lg, .MuiTypography-title-md, .MuiTypography-title-sm':
          {
            color: `${calculatedColors?.Typography?.text === 'light' ? 'var(--joy-palette-text-primary)' : 'var(--joy-palette-textOnDark-primary)'} !important`,
          },
        // * Typography - secondary
        '.MuiTypography-body-lg, .MuiTypography-body-md, .MuiTypography-code-md': {
          color: `${calculatedColors?.Typography?.text === 'light' ? 'var(--joy-palette-text-secondary)' : 'var(--joy-palette-textOnDark-secondary)'} !important`,
        },
        // * Typography - tertiary
        '.MuiTypography-body-sm, .MuiTypography-body-xs, .MuiTypography-code-sm, .MuiTypography-code-xs': {
          color: `${calculatedColors?.Typography?.text === 'light' ? 'var(--joy-palette-text-tertiary)' : 'var(--joy-palette-textOnDark-tertiary)'} !important`,
        },
        // * Typography - eyebrow
        '.MuiTypography-eyebrow': {
          color: `${calculatedColors?.Typography?.eyebrow} !important`,
        },
        // * List Item
        '.MuiListItemDecorator-root': {
          color: `${calculatedColors?.ListItem?.listItemDecorator} !important`,
        },
        // * Quote
        '.SectionQuote .MuiTypography-display-md': {
          color: `${calculatedColors?.Quote?.display} !important`,
        },
        '.SectionQuote .MuiCard-root .MuiTypography-display-md': {
          color: `${calculatedColors?.Quote?.Card?.display} !important`,
        },
        '.SectionQuote .MuiTypography-display-sm': {
          color: `${calculatedColors?.Quote?.display} !important`,
        },
        '.SectionQuote .MuiCard-root .MuiTypography-display-sm': {
          color: `${calculatedColors?.Quote?.Card?.display} !important`,
        },
        // * SVG
        '.blok-svg svg': {
          fill: calculatedColors?.Svg?.image,
        },
        '.blok-svg path': {
          fill: calculatedColors?.Svg?.image,
        },
        '.blok-icon svg': {
          stroke: calculatedColors?.Svg?.icon,
        },
        '.blok-icon path': {
          stroke: calculatedColors?.Svg?.icon,
        },
        // * Card
        '.MuiCard-root': {
          backgroundColor: `${calculatedColors?.Card?.backgroundColor} !important`,
          border: `1px solid ${calculatedColors.Card.border}`,
        },
        '.MuiCard-root .Mui-error .MuiInput-colorDanger': {
          '--_Input-focusedHighlight': `${calculatedColors?.Card?.Input.invalid} !important`,
        },
        '.MuiCard-root .Mui-error .MuiInput-colorDanger .MuiInput-input': {
          color: `${calculatedColors?.Card?.Input.invalid} !important`,
        },
        '.MuiCard-root .Mui-error .MuiFormHelperText-root': {
          color: `${calculatedColors?.Card?.Input.invalid} !important`,
        },
        '.MuiCard-root .blok-icon svg': {
          stroke: calculatedColors?.Card?.Svg?.icon,
        },
        '.MuiCard-root .blok-icon path': {
          stroke: calculatedColors?.Card?.Svg?.icon,
        },
        //* Job Openings Card
        '.job-openings-card': {
          borderLeft: `4px solid ${calculatedColors?.JobOpeningsCard?.borderColor}`,
        },
        // * Card With Form
        '.MuiCard-root.CardWithForm': {
          backgroundColor: `${calculatedColors?.CardWithForm?.backgroundColor} !important`,
        },
        // * Footer
        '&:is(footer) .intro-text': {
          color: `${calculatedColors?.Footer?.introText} !important`,
        },
        '&:is(footer) .MuiTypography-root': {
          color: `${calculatedColors?.Footer?.text}  !important`,
        },
        // Done after so it overrides the above
        '&:is(footer) .MuiTypography-title-lg': {
          color: `${calculatedColors?.Footer?.listTitle} !important`,
        },
        '&:is(footer) .MuiCard-root .MuiTypography-title-lg': {
          color: `${calculatedColors?.Footer?.listTitle} !important`,
        },
        // * Accordion
        '.MuiAccordion-root .MuiAccordionSummary-button': {
          color: `${calculatedColors?.Accordion?.summaryText} !important`,
        },
        '.MuiAccordion-root .MuiAccordionSummary-indicator svg': {
          color: `${calculatedColors?.Accordion?.icon} !important`,
        },
        '.MuiAccordionSummary-button:not(.Mui-selected, [aria-selected="true"]):hover': {
          backgroundColor: `${calculatedColors?.Accordion?.hoverBg} !important`,
        },
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default Section
