import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Card from '@audioeye-marketing/ui/src/components/Card'
import Box from '@audioeye-marketing/ui/src/components/Box'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import UIList from '@audioeye-marketing/ui/src/components/List'
import Stack from '@audioeye-marketing/ui/src/components/Stack'

const List = ({ blok, backgroundColor }) => {
  const Wrapper = blok?.card ? Card : Box
  const wrapperProps = blok?.card ? { noBorderRadius: true, variant: 'plain' } : {}

  return (
    <Wrapper
      {...storyblokEditable(blok)}
      {...wrapperProps}
      sx={{
        padding: { xs: 2, md: 3 },
        width: '100%',
        height: '100%',
      }}
    >
      <Stack spacing={1.25}>
        {Boolean(blok?.heading) && <Typography level="title-lg">{blok?.heading}</Typography>}
        {Boolean(blok?.items?.length > 0) && (
          <UIList>
            <Stack spacing={1}>
              {blok?.items?.map((currentItem) => {
                return (
                  <StoryblokServerComponent
                    key={currentItem?._uid}
                    blok={currentItem}
                    backgroundColor={backgroundColor}
                    level="body-sm"
                  />
                )
              })}
            </Stack>
          </UIList>
        )}
      </Stack>
    </Wrapper>
  )
}

export default List
